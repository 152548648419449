@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Saira+Condensed&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background-color: #151515;
}

@font-face {
  font-family: "Saira Condensed";
  src: url("./assets/fonts/Saira.ttf");
}

@font-face {
  font-family: "Saira Condensed Light";
  src: url("./assets/fonts/Saira-ExtraLight.ttf");
}

.text {
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #868686;
  color: transparent;
  text-decoration: none;
}
